import React from 'react';
import { Link } from "gatsby";
import LogoIconFull from '../../svg/LogoFull';

const Header = () => (
  
  <header className="sticky top-0 gradient shadow-md">
    <div class="bg-gray-900 text-gray-600">
    <div class="container px-5 py-4 sm:py-2 mx-auto flex items-center sm:flex-row flex-col">
      <p class="text-sm sm:ml-6 hidden sm:inline">© 2020 Hollis Meddings Group</p>
      {/* <span class="inline-flex sm:ml-auto justify-center sm:justify-start">
          <a href="https://www.facebook.com" class="text-gray-600 hover:text-white cursor-pointer"> <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24"> <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path> </svg> </a>
          <a href="https://www.twitter.com" class="ml-3 text-gray-600 hover:text-white cursor-pointer"> <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24"> <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path> </svg> </a>
          <a href="https://www.instagram.com" class="ml-3 text-gray-600 hover:text-white cursor-pointer"> <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24"> <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect> <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path> </svg> </a>
          <a href="https://www.linkedin.com" class="ml-3 text-gray-600 hover:text-white cursor-pointer"> <svg fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="0" class="w-5 h-5" viewBox="0 0 24 24"> <path stroke="none" d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"></path> <circle cx="4" cy="4" r="2" stroke="none"></circle> </svg> </a>
      </span> */}
      <span class="inline-flex sm:ml-auto justify-center sm:justify-start text-sm ">
        (401) 421-3330
      </span>
    </div>
  </div>
    <div className="container flex flex-col sm:flex-row justify-between items-center mx-auto py-4 px-8">
      <div className="flex items-center text-2xl">
        <div className="mt-8 sm:mt-0 logo">
          <Link to="/" class="logo">
            <LogoIconFull />
          </Link>          
        </div>
      </div>
      <nav className="flex mt-4 sm:mt-0">
        <Link to="/our-firm" activeClassName="active" className="px-4"><span class="hidden md:inline">Our </span>Firm</Link>
        <Link to="/service-offerings" activeClassName="active" className="px-4"><span class="hidden md:inline">Our Service </span>Offerings</Link>
        <Link to="/our-leadership" activeClassName="active" className="px-4"><span class="hidden md:inline">Our </span>Leadership</Link>
        <Link to="/news" activeClassName="active" className="px-4">News</Link>
        <Link to="/contact-us" activeClassName="active" className="px-4">Contact<span class="hidden md:inline"> Us</span></Link>
      </nav>
    </div>
  </header>
);

export default Header;
